<template>
	<page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Multiple Selections" active/>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content>
      <table-layout ref="table-layout"
                    :fields="table.fields"
                    :filters="table.filters"
										:filters-options="{ visible: true, collapsed: false, cols: 20 }"
                    :items="table.items"
                    :loading="table.loading"
                    :is-searchable="true"
                    @mounted="table = $event"
                    @updated="table = $event"
                    @filtered="(value) => onTableFilter(value)">

				<template #filters>
					<b-form-group label="School" label-for="filters-school">
						<v-select id="filters-school"
											v-model="table.filters.school.value"
                      :loading="filters.schools.loading"
											:options="filters.schools.items" label="name"
											:reduce="option => option.id"
											:filter="filterSchools"
											searchable clearable select-on-tab
											class="font-small-3">
							<template #option="{ name }">
                  <h6 class="mb-0 font-small-3">{{ name.legal }}</h6>
                  <small class="text-muted">{{ name.popular }}</small>
							</template>
              <template #selected-option="{ name }">
                <h6 class="mb-0 font-small-3">{{ name.legal }}</h6>
              </template>
						</v-select>
					</b-form-group>

          <template v-if="filters.filterExpansionRow">
            <hr class="my-2"/>
            <b-card-title class="d-flex align-items-center justify-content-between w-100">
              <div class="d-flex align-items-center">
                <b-icon icon="filter-left" class="mr-50" style="width: 16px; height: 16px" />
                <span class="align-middle">Expansion Row Filters</span>
              </div>
            </b-card-title>
            <b-form-group label="Acceptance" label-for="filters-acceptance">
              <v-select id="filters-acceptance"
                        v-model="table.filters.acceptance.value"
                        :loading="filters.acceptance.loading"
                        :options="filters.acceptance.items" label="name"
                        :reduce="option => option.value"
                        clearable select-on-tab
                        class="font-small-3">
                <template #option="{ name }">
                  <span class="mb-0 font-small-3">{{ name }}</span>
                </template>
                <template #selected-option="{ name }">
                  <span class="mb-0 font-small-3">{{ name }}</span>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group label="Ensemble" label-for="filters-ensemble">
              <v-select id="filters-ensemble"
                        v-model="table.filters.ensemble.value"
                        :loading="filters.ensembles.loading"
                        :options="filters.ensembles.items" label="name"
                        :reduce="option => option.id"
                        :searchable="true"
                        :clearable="true"
                        :select-on-tab="true"
                        class="font-small-3"
              />
            </b-form-group>
            <b-form-group label="Instrument" label-for="filters-instrument">
              <v-select id="filters-instrument"
                        v-model="table.filters.instrument.value"
                        :loading="filters.instruments.loading"
                        :options="filters.instruments.items" label="name"
                        :reduce="option => option.id"
                        :searchable="true"
                        :clearable="true"
                        :select-on-tab="true"
                        class="font-small-3"
              />
            </b-form-group>
          </template>
				</template>

        <template #sidebar>
          <b-card-actions body-class="px-1" action-collapse>
            <template #title>
              <b-card-title class="d-flex align-middle">
                <b-icon icon="gear" class="mr-50" style="width: 16px; height: 16px" />
                <span class="align-middle">Filter Options</span>
              </b-card-title>
            </template>
            <b-list-group flush>
              <b-list-group-item class="rounded-top px-75">
                <b-row no-gutters>
                  <b-col align-self="center">
                    <label class="m-0">Include Alternates:</label>
                  </b-col>
                  <b-col cols="auto" align-self="center">
                    <b-checkbox v-model="filters.includeAlternates" :value="true" :unchecked-value="false" inline class="mr-25 px-50"/>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item class="px-75">
                <b-row no-gutters>
                  <b-col align-self="center">
                    <label class="m-0">Include Expansion Filters:</label>
                  </b-col>
                  <b-col cols="auto" align-self="center">
                    <b-checkbox v-model="filters.filterExpansionRow" :value="true" :unchecked-value="false" inline class="mr-25 px-50"/>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item class="rounded-bottom px-75">
                <b-row no-gutters>
                  <b-col align-self="center">
                    <label class="m-0">Expand All Rows:</label>
                  </b-col>
                  <b-col cols="auto" align-self="center">
                    <b-checkbox v-model="filters.areRowsExpanded" :value="true" :unchecked-value="false" inline class="mr-25 px-50"/>
                  </b-col>
                </b-row>
              </b-list-group-item>


            </b-list-group>
          </b-card-actions>
        </template>



				<template #cell(student)="{data}">
					<b-media vertical-align="center" no-body>
						<b-media-aside>
							<b-avatar size="2.5em"
												variant="primary" badge-variant="primary"
												button @click="data.toggleDetails">
								<font-awesome-icon icon="fas fa-graduation-cap"></font-awesome-icon>
								<template #badge>
									<b-icon :icon="data.item._showDetails === true ? 'chevron-up' : 'chevron-down'" />
								</template>
							</b-avatar>
						</b-media-aside>
						<b-media-body class="align-self-center">
							<b-link :to="{ name: 'all-state-student', params: { id: data.item.student.id } }" class="font-weight-bold d-block text-nowrap">
								{{ data.item.student.name.first }} {{ data.item.student.name.last }}
							</b-link>
              <template>
                <small v-if="hasValue(data, 'item.school.name')" class="d-block">
                  {{ data.item.school.name.legal }}
                </small>
                <small v-else class="text-danger">No School</small>
              </template>

						</b-media-body>
					</b-media>
				</template>

				<template #cell(school)="{data}">
          <template v-if="hasValue(data, 'item.school.name')">
            <span>
              {{ data.item.school.name.legal }}
            </span>
            <small class="d-block">
              {{ data.item.school.name.popular }}
            </small>
          </template>
          <template v-else>
            <span class="text-danger">
              No School
            </span>
          </template>
				</template>

        <template #cell(status)="{data}">
          <b-badge v-if="data.item.selections.reduce((acc, selection) => acc + (selection.accepted === 'true' ? 1 : 0), 0) > 1"
                  variant="danger">
            Accepted {{ data.item.selections.reduce((acc, selection) => acc + (selection.accepted === 'true' ? 1 : 0), 0) }}x
          </b-badge>
          <b-badge v-else variant="success">
            No Issues
          </b-badge>
        </template>

        <template #row-details="{ item }">
          <b-table-simple class="d-print-none">
            <b-thead>
              <b-tr>
                <b-th class="col-instrument">Instrument</b-th>
                <b-th class="col-ensemble">Ensemble</b-th>
                <b-th class="col-part">Part</b-th>
                <b-th class="col-score">Score</b-th>
                <b-th class="col-local">Local Rank</b-th>
                <b-th class="col-state">State Rank</b-th>
                <b-th class="col-acceptance">Acceptance</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="selection in item.selections.filter(s => includeSelection(s))" :key="selection.id">
                <b-td class="col-student">
                  <b-media vertical-align="center" no-body>
                    <b-media-aside>
                      <b-avatar variant="secondary" size="2.5em">
                        <font-awesome-icon icon="fas fa-clipboard"></font-awesome-icon>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="align-self-center">
                      <span v-if="hasValue(selection, 'instrument')">{{ selection.instrument.name }}</span>
                      <span v-else class="text-danger">No Instrument</span>
                    </b-media-body>
                  </b-media>
                </b-td>
                <b-td class="col-ensemble">
                  <span v-if="hasValue(selection, 'ensemble')">{{ selection.ensemble.name }}</span>
                  <span v-else class="text-danger">No Ensemble</span>
                </b-td>
                <b-td class="col-part">
                  {{ selection.part }}
                </b-td>
                <b-td class="col-score">
                  {{ selection.ranking.score }}
                </b-td>
                <b-td class="col-local">
                  {{ selection.ranking.local }}
                </b-td>
                <b-td class="col-state">
                  {{ selection.ranking.state }}
                </b-td>
                <b-td class="col-acceptance">
                  <b-icon :icon="getAcceptanceIcon(selection.accepted)" :variant="getAcceptanceVariant(selection.accepted)" style="width: 16px; height: 16px;"/>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>

      </table-layout>
    </template>
    <template #debug>
      <debug>
        {{ selections }}
      </debug>
			<debug>
				{{ filters }}
			</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import TableLayout from '@/components/TableLayout.vue';
import vSelect from 'vue-select';
import {API, graphqlOperation} from 'aws-amplify';
import {listSelections} from './queries/multiple-selections';
import Fuse from 'fuse.js';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

export default {
	name: 'MultipleSelections',
	components: {
    BCardActions,
		vSelect,
		TableLayout,
		PageLayout
	},
  mixins: [lodashMixin, settingsMixin],
	data() {
		return {
			selections: {
				items: [],
				loading: true
			},
			table: {
				loading: true,
				fields: [
					{
						key: 'student',
						subKeys: ['student.name.first', 'student.name.last'],
						label: 'Name',
						sortable: true,
						filterable: true,
						visible: true,
						tdClass: 'col-student-name'
					},
					{
						key: 'school',
						subKeys: ['school.name.legal', 'school.name.popular'],
						label: 'School',
						sortable: true,
						filterable: true,
						visible: false,
						tdClass: 'align-middle',
            thClass: 'align-middle'
					},
					{
						key: 'selections.length',
						label: 'Selections',
						sortable: true,
						filterable: true,
						visible: true,
						tdClass: 'col-selections',
            thClass: 'col-selections'
					},
          {
            key: 'status',
            label: 'Status',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'col-status',
            thClass: 'col-status'
          },
				],
				items: [],
				filters: {
					instrument: { key: 'selections.instrument.id', value: null },
					ensemble: { key: 'selections.ensemble.id', value: null },
					school: { key: 'school.id', value: null },
          acceptance: { key: 'selections.accepted', value: null },
				},
			},
			filters: {
        includeAlternates: true,
        isFiltered: false,
        areRowsExpanded: false,
        filterExpansionRow: true,
        expandAllOnFilter: false,
				instruments: {
          items: [],
          loading: true,
        },
				ensembles: {
          items: [],
          loading: true,
        },
				schools: {
          items: [],
          loading: true,
        },
        acceptance: {
          items: [
            {
              value: 'true',
              name: 'Accepted'
            },
            {
              value: 'false',
              name: 'Rejected'
            },
            {
              value: 'null',
              name: 'Undecided'
            },
          ],
          loading: false,
        }
			}
		}
	},
  watch: {
    'filters.areRowsExpanded': function (value) {
      this.expandExpansionRows(value)
    },
  },
	async mounted() {
    this.$refs.layout.state.loading = false
		await this.listSelections()
	},
	methods: {
    async refresh() {
      this.table.loading = true
      await this.listSelections()
      if (this.table.items.length === 0) {
        this.table.filters.instrument.value = null
        this.table.filters.ensemble.value = null
        this.table.filters.school.value = null
      }
    },
    async listSelections(nextToken, pagedItems) {
      this.table.loading = true
      this.filters.instruments.loading = true
      this.filters.ensembles.loading = true
      this.filters.schools.loading = true

      const items = pagedItems || []
      const input = {
        filter: {
          //accepted: { eq: true },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        },
        limit: 500,
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      items.push(...response.data.listSelections.items);

      if (response.data.listSelections.nextToken) {
        await this.listSelections(response.data.listSelections.nextToken, items)
      }
      else {
        this.selections.items = items
        this.selections.loading = false

        this.table.items = this.processSelections(items)
        this.table.loading = false
      }
    },

    processSelections(selections) {
      const studentsMap = {};
      const instrumentsMap = {};
      const ensemblesMap = {};
      const schoolsMap = {};

      selections.forEach((selection) => {
        const studentId = selection?.application?.student?.id;
        const student = {
          id: studentId,
          name: selection?.application?.student?.name
        };
        const school = selection?.application?.student?.school ? {
          id: selection?.application?.student?.school?.id,
          name: selection?.application?.student?.school?.name
        } : null;

        const mappedSelection = {
          accepted: `${selection.accepted}`,
          part: selection.part,
          ensemble: selection?.ensemble,
          instrument: selection?.application?.instrument,
          festival: selection?.application?.festival,
          ranking: {
            score: selection?.application?.ranking?.score,
            local: selection?.application?.ranking?.local,
            state: selection?.ranking?.state
          }
        };

        if (studentsMap[studentId]) {
          studentsMap[studentId].selections.push(mappedSelection);
        }
        else {
          studentsMap[studentId] = {
            student: student,
            school: school,
            selections: [mappedSelection],
            _showDetails: false
          };
        }

        // Add unique instruments, ensembles, and schools to their respective maps
        if (selection?.application?.instrument?.id) {
          instrumentsMap[selection?.application?.instrument?.id] = {
            id: selection?.application?.instrument?.id,
            name: selection?.application?.instrument?.name,
          };
        }

        if (selection?.ensemble?.id) {
          ensemblesMap[selection?.ensemble?.id] = {
            id: selection?.ensemble?.id,
            name: selection?.ensemble?.name,
            code: selection?.ensemble?.code,
          };
        }

        if (selection?.application?.student?.school?.id) {
          schoolsMap[selection?.application?.student?.school?.id] = {
            id: selection?.application?.student?.school?.id,
            name: selection?.application?.student?.school?.name,
          };
        }
      });

      // Populate filters with unique values
      this.filters.instruments.items = Object.values(instrumentsMap)
          .sort((a, b) => (a?.order - b?.order) || a?.name?.localeCompare(b?.name))
      this.filters.instruments.loading = false

      this.filters.ensembles.items = Object.values(ensemblesMap)
          .sort((a, b) => a?.name?.localeCompare(b?.name));
      this.filters.ensembles.loading = false

      this.filters.schools.items = Object.values(schoolsMap)
          .sort((a, b) => a?.name?.legal?.localeCompare(b?.name?.legal));
      this.filters.schools.loading = false

      // Convert the students object to an array
      return Object.values(studentsMap)
          .filter((student) => student?.selections?.length > 1);
    },
    filterSchools(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        shouldSort: true,
      })
      return search.length ? fuse.search(search)
          .map(({item}) => item) : fuse.list
    },
    getAcceptanceIcon(accepted) {
      switch(this.toBoolean(accepted)) {
        case true:
          return 'check-circle-fill';
        case false:
          return 'x-circle-fill';
        default:
          return 'slash-circle-fill';
      }
    },
    getAcceptanceVariant(accepted) {
      switch(this.toBoolean(accepted)) {
        case true:
          return 'success';
        case false:
          return 'danger';
        default:
          return 'warning';
      }
    },
    toBoolean(value) {
      const trimmedValue = value?.trim();
      if(!trimmedValue || trimmedValue === '' || trimmedValue === 'null') return null;
      return (trimmedValue === 'true' || value === true);
    },
    includeSelection(selection) {
      const { includeAlternates, filterExpansionRow } = this.filters;


      const shouldInclude = (_selection) => {
        const { acceptance, instrument, ensemble } = this.table.filters;

        if (!filterExpansionRow) return true;
        // Check each filter; if a filter is set, ensure the selection matches it
        if (acceptance.value !== null && (acceptance?.value !== _selection?.accepted)) return false;
        if (ensemble.value !== null && (ensemble.value !== _selection?.ensemble?.id)) return false;
        if (instrument.value !== null && (instrument.value !== _selection?.instrument?.id)) return false;
        return true
      }

      if(!includeAlternates) {
        if(selection?.ensemble?.code === 'A') return false
        return shouldInclude(selection)
      }
      return shouldInclude(selection)
    },
    onTableFilter(value) {
      //this.filters.isFiltered = value;
    },
    expandExpansionRows(value) {
      this.table.items.forEach((item) => {
        item._showDetails = value;
      })
    }
  },

}
</script>

<style>
.table.b-table > tbody > tr.b-table-details > td {
	border-top: none !important;
  border-bottom: var(--input-border);
	padding: 0 0 1rem 0 !important;
}

.col-student-name, .col-instrument {
  width: 30%;
  vertical-align: middle !important;
}

.col-selections {
  width: auto;
  vertical-align: middle !important;
}

.col-status, .col-part, .col-score, .col-local, .col-state, .col-acceptance {
  width: 10%;
  text-align: center;
  vertical-align: middle !important;
}
</style>
